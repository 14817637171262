import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import router from "./router";
import DynamicButton from "./components/dynamic/others/button.vue";
import Lobby from "./components/games/lobby.vue";
import GameThumbnail from "./components/games/gameThumbnail.vue";
import Dynamic from "./components/dynamic/dynamic.vue";
import VueLazyload from "@jambonn/vue-lazyload";
import globalMixin from "./helpers/mixin";

//Bootstrap Related
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import $ from "jquery";
window.$ = $;
import "jquery-easing";


//Swiper Styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/a11y";

// FontAwesome Related
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMessagesQuestion, faDoorClosed, faCoinVertical, faGameConsoleHandheld, faShieldCheck, faHundredPoints, faBellRing, faTriangleExclamation, faFutbol, faCourtSport, faSlotMachine as faSlotMachine2, faCards as faCards2 } from "@fortawesome/pro-solid-svg-icons"
import { faCirclePlus, faCircleMinus } from "@fortawesome/pro-regular-svg-icons";
import { faDharmachakra } from "@fortawesome/pro-light-svg-icons";
import { faSlotMachine, faFireFlameCurved, faSparkles, faCards, faCardSpade, faEllipsis, faCoins, faTireFlat, faTv} from "@fortawesome/pro-thin-svg-icons";
import { faHouse, faPlay, faLock, faExpand, faThumbsDown, faThumbsUp, faExclamation, faUser, faSearch, faUserPlus, faClipboardCheck, fa1, fa2, fa3, fa4, faKey, faFile, faGift, faClockRotateLeft, faUserShield, faHand, faChevronLeft, faFilter, faDoorOpen, faSpinner, faCircleInfo, faWallet, faMessage, faGlobe, faChevronUp, faEye, faEnvelope, faSms, faStar, faShop, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { faUser as faUserRegular, faFaceSmile } from '@fortawesome/free-regular-svg-icons';
library.add(faHouse, faPlay, faLock, faExpand, faThumbsDown, faThumbsUp, faExclamation, faUser, faUserPlus, faSearch, faClipboardCheck, fa1, fa2, fa3, fa4, faFile, faKey, faGift, faClockRotateLeft, faUserShield, faHand, faChevronLeft, faFilter, faDoorOpen, faSpinner, faCircleInfo, faWallet, faMessage, faGlobe, faChevronUp, faEye, faEnvelope, faSms, faStar, faShop, faFireFlameCurved, faSlotMachine, faSparkles, faCards, faCardSpade, faEllipsis, faMessagesQuestion, faDoorClosed, faCoinVertical, faCoins, faTireFlat, faTv, faGameConsoleHandheld, faUserRegular, faShieldCheck, faHundredPoints, faCirclePlus, faCircleMinus, faFaceSmile, faDharmachakra, faBellRing, faTriangleExclamation, faFutbol, faCourtSport, faSlotMachine2, faCards2, faScrewdriverWrench);

const app = createApp(App);

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.mixin(globalMixin);

app.use(VueLazyload, {
    attempt: 1,
});

import * as Sentry from "@sentry/vue";
Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY,
    environment: import.meta.env.VITE_ENV,
    enabled: import.meta.env.VITE_ENV === "prod",
    trackComponents: true,
    integrations: [Sentry.browserTracingIntegration({ router })],
});

app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("dynamic", Dynamic);
// The below components are globally exposed so that they can be used in the CMS Html content
app.component("dynamic-button", DynamicButton);
app.component("lobby", Lobby);
app.component("game-thumbnail", GameThumbnail);
app.mount("#app");
